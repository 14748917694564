import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './FullAdvertisingWrapper.module.scss';

const FullAdvertisingWrapper = ({ padded, inArticle, inFeed }) => {
  return (
    <div
      className={classNames({
        [styles.full_padded]: padded,
        [styles.full_inArticle]: inArticle,
        [styles.full_inFeed]: inFeed,
      })}
    >
      <div className={styles.full__container} data-print="none">
        <p>Advertisement</p>
        <div className="amu-mid-ad" />
      </div>
    </div>
  );
};

FullAdvertisingWrapper.propTypes = {
  inArticle: PropTypes.bool,
  inFeed: PropTypes.bool,
  padded: PropTypes.bool,
};

FullAdvertisingWrapper.defaultProps = {
  padded: false,
  inArticle: false,
  inFeed: false,
};

export default FullAdvertisingWrapper;
